import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row g-6 g-xl-9" }
const _hoisted_2 = { class: "col-md-6 col-xl-4" }
const _hoisted_3 = { class: "col-md-6 col-xl-4" }
const _hoisted_4 = { class: "col-md-6 col-xl-4" }
const _hoisted_5 = { class: "col-md-6 col-xl-4" }
const _hoisted_6 = { class: "col-md-6 col-xl-4" }
const _hoisted_7 = { class: "col-md-6 col-xl-4" }
const _hoisted_8 = { class: "col-md-6 col-xl-4" }
const _hoisted_9 = { class: "col-md-6 col-xl-4" }
const _hoisted_10 = { class: "col-md-6 col-xl-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KTCard = _resolveComponent("KTCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[1] || (_cache[1] = _createStaticVNode("<div class=\"d-flex flex-wrap flex-stack my-5\"><h2 class=\"fs-2 fw-bold my-2\"> Projects <span class=\"fs-6 text-gray-400 ms-1\">by Status</span></h2><div class=\"d-flex flex-wrap my-1\"><div class=\"m-0\"><select name=\"status\" data-control=\"select2\" data-hide-search=\"true\" class=\"form-select form-select-white form-select-sm fw-bolder w-125px\"><option value=\"Active\" selected>Active</option><option value=\"Approved\">In Progress</option><option value=\"Declined\">To Do</option><option value=\"In Progress\">Completed</option></select></div></div></div>", 1)),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_KTCard, {
          progress: 50,
          title: "Fitnes App",
          icon: "media/svg/brand-logos/plurk.svg",
          users: _ctx.users1
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_KTCard, {
          status: "Pending",
          "status-data-badge-color": "badge-light",
          progress: 30,
          icon: "media/svg/brand-logos/disqus.svg",
          title: "Leaf CRM",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users2
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_KTCard, {
          status: "Completed",
          "status-data-badge-color": "badge-light-success",
          progress: 100,
          icon: "media/svg/brand-logos/figma-1.svg",
          title: "Atica Banking",
          date: "Mar 14, 2021",
          budget: "$605,100.00",
          users: _ctx.users3
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_KTCard, {
          status: "Pending",
          progress: 60,
          "status-data-badge-color": "badge-light",
          icon: "media/svg/brand-logos/sentry-3.svg",
          title: "Finance Dispatch",
          budget: "$36,400.00",
          users: _ctx.users4
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_KTCard, {
          status: "In Progress",
          progress: 40,
          icon: "media/svg/brand-logos/xing-icon.svg",
          title: "9 Degree",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users5
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_KTCard, {
          status: "In Progress",
          progress: 70,
          icon: "media/svg/brand-logos/tvit.svg",
          title: "GoPro App",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users6
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_KTCard, {
          status: "In Progress",
          progress: 30,
          icon: "media/svg/brand-logos/aven.svg",
          title: "Buldozer CRM",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users7
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_KTCard, {
          status: "Overdue",
          progress: 10,
          "status-data-badge-color": "badge-light-danger",
          icon: "media/svg/brand-logos/treva.svg",
          title: "Aviasales App",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users8
        }, null, 8, ["users"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_KTCard, {
          status: "Completed",
          progress: 100,
          "status-data-badge-color": "badge-light-success",
          icon: "media/svg/brand-logos/kanba.svg",
          title: "Oppo CRM",
          date: "May 10, 2021",
          budget: "$36,400.00",
          users: _ctx.users9
        }, null, 8, ["users"])
      ]),
      _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"d-flex flex-stack flex-wrap pt-10\"><div class=\"fs-6 fw-bold text-gray-700\"> Showing 1 to 10 of 50 entries </div><ul class=\"pagination\"><li class=\"page-item previous\"><a href=\"#\" class=\"page-link\"><i class=\"previous\"></i></a></li><li class=\"page-item active\"><a href=\"#\" class=\"page-link\">1</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">2</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">3</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">4</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">5</a></li><li class=\"page-item\"><a href=\"#\" class=\"page-link\">6</a></li><li class=\"page-item next\"><a href=\"#\" class=\"page-link\"><i class=\"next\"></i></a></li></ul></div>", 1))
    ])
  ], 64))
}