import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  href: "#",
  class: "card border border-2 border-gray-300 border-hover"
}
const _hoisted_2 = { class: "card-header border-0 pt-9" }
const _hoisted_3 = { class: "card-title m-0" }
const _hoisted_4 = { class: "symbol symbol-50px w-50px bg-light" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = { class: "card-body p-9" }
const _hoisted_8 = { class: "fs-3 fw-bolder text-dark" }
const _hoisted_9 = { class: "text-gray-400 fw-bold fs-5 mt-1 mb-7" }
const _hoisted_10 = { class: "d-flex flex-wrap mb-5" }
const _hoisted_11 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3" }
const _hoisted_12 = { class: "fs-6 text-gray-800 fw-bolder" }
const _hoisted_13 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3" }
const _hoisted_14 = { class: "fs-6 text-gray-800 fw-bolder" }
const _hoisted_15 = ["title"]
const _hoisted_16 = ["aria-valuenow"]
const _hoisted_17 = {
  key: 0,
  class: "symbol-group symbol-hover"
}
const _hoisted_18 = ["title"]
const _hoisted_19 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("a", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("img", {
            src: _ctx.icon,
            alt: "image",
            class: "p-3"
          }, null, 8, _hoisted_5)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("span", {
          class: _normalizeClass([_ctx.getStatusDataBadgeColor, "badge fw-bolder me-auto px-4 py-3"])
        }, _toDisplayString(_ctx.getStatus), 3)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.getDescription), 1),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.getDate), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Due Date", -1))
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.getBudget), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "fw-bold text-gray-400" }, "Budget", -1))
        ])
      ]),
      _createElementVNode("div", {
        class: "h-4px w-100 bg-light mb-5",
        "data-bs-toggle": "tooltip",
        title: `This project ${_ctx.progress}% completed`
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([_ctx.getStatusDataColor, "rounded h-4px"]),
          role: "progressbar",
          style: _normalizeStyle({ width: _ctx.progress + `%` }),
          "aria-valuenow": _ctx.progress,
          "aria-valuemin": "0",
          "aria-valuemax": "100"
        }, null, 14, _hoisted_16)
      ], 8, _hoisted_15),
      (_ctx.users)
        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.users, (user, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "symbol symbol-35px symbol-circle",
                "data-bs-toggle": "tooltip",
                title: user.title
              }, [
                (user.src)
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      alt: "Pic",
                      src: user.src
                    }, null, 8, _hoisted_19))
                  : (_openBlock(), _createElementBlock("span", {
                      key: 1,
                      class: _normalizeClass(["symbol-label fw-bolder", `bg-${user.state} text-inverse-${user.state}`])
                    }, _toDisplayString(user.initials), 3))
              ], 8, _hoisted_18))
            }), 128))
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}